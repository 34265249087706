import { useState } from 'react';
import { Descriptions, Divider, Form, Select, TimePicker, notification } from 'antd';
import ruLocale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';

import * as BackgroundJobService from '../../core/services/backgroundJob';
import { formatDateTime, makeRequiredRule } from '../../core/utilities/callables';

import CardWithButtonGroup from '../CardWithButtonGroup';
import { filterOption, isFormFieldValueChanged } from '../utilities/callables';


const BackgroundJob = ({
    title,
    backgroundJob = null,
    backgroundJobIdOptions,
    creationForm: isCreationForm = false,
    onSubmit,
}) => {
    const [ form ] = Form.useForm();
    const [ isBeingEdited, setIsBeingEdited ] = useState(isCreationForm);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ notificator, notificationContextHolder ] = notification.useNotification();
    const [ isBackgroundJobIdSelectOpen, setIsBackgroundJobIdSelectOpen ] = useState(false);
    const [ isWeekDaySelectOpen, setIsWeekDaySelectOpen ] = useState(false);
    const [ isTimePickerOpen, setIsTimePickerOpen ] = useState(false);

    const weekDayOptions = [
        { label: 'Понедельник', value: 0 },
        { label: 'Вторник', value: 1 },
        { label: 'Среда', value: 2 },
        { label: 'Четверг', value: 3 },
        { label: 'Пятница', value: 4 },
        { label: 'Суббота', value: 5 },
        { label: 'Воскресенье', value: 6 },
    ]

    const onBackgroundJobIdSelectOpenChange = (isOpen) => {
        setIsBackgroundJobIdSelectOpen(isBeingEdited && isOpen);
    };

    const onWeekDaySelectOpenChange = (isOpen) => {
        setIsWeekDaySelectOpen(isBeingEdited && isOpen);
    };

    const onTimePickerOpenChange = (isOpen) => {
        setIsTimePickerOpen(isBeingEdited && isOpen);
    };

    const editBackgroundJob = () => {
        if (isCreationForm) { return; }

        setIsBeingEdited(true);
    };

    const cancelEditingBackgroundJob = () => {
        form.resetFields();
        setIsBeingEdited(false);
    };

    const saveBackgroundJob = async () => {
        const formDataEntries = Object.entries(form.getFieldsValue()).filter(
            ([ fieldName, fieldValue ]) => isFormFieldValueChanged(backgroundJob, fieldName, fieldValue, isCreationForm),
        );

        if (formDataEntries.length === 0) {
            if (!isCreationForm) {
                setIsBeingEdited(false);                
            }

            return;
        }

        try {
            await form.validateFields();
        } catch {
            return;
        }

        setIsLoading(true);

        const formData = Object.fromEntries(formDataEntries);

        try {
            if (isCreationForm) {
                await BackgroundJobService.createBackgroundJob(
                    formData.id,
                    formData.day_of_week,
                    formData.time?.hour(),
                    formData.time?.minute(),
                );
            } else {
                await BackgroundJobService.updateBackgroundJobSchedule(
                    backgroundJob.id,
                    formData.day_of_week,
                    formData.time?.hour(),
                    formData.time?.minute(),
                );

                setIsBeingEdited(false);
            }

            onSubmit();
        } catch (error) {
            notificator.error({
                message: `Ошибка ${isCreationForm ? 'создания' : 'обновления'} фонового процесса`,
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteBackgroundJob = async () => {
        setIsLoading(true);

        try {
            await BackgroundJobService.deleteBackgroundJob(backgroundJob.id);

            onSubmit();
        } catch (error) {
            notificator.error({
                message: 'Ошибка удаления фонового процесса',
                description: error.message,
                placement: 'bottom',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {notificationContextHolder}

            <CardWithButtonGroup
                title={title}
                isCreationForm={isCreationForm}
                isBeingEdited={isBeingEdited}
                isLoading={isLoading}
                onEdit={editBackgroundJob}
                onCancelEditing={cancelEditingBackgroundJob}
                onSave={saveBackgroundJob}
                onDelete={deleteBackgroundJob}
            >
                <Form layout='vertical' form={form} initialValues={backgroundJob && {
                    id: backgroundJob.id,
                    day_of_week: backgroundJob.trigger.day_of_week,
                    time: dayjs().hour(backgroundJob.trigger.hour).minute(backgroundJob.trigger.minute),
                }}>
                    {isCreationForm && (
                        <Form.Item
                            name='id'
                            label='Идентификатор'
                            rules={[ makeRequiredRule('Выберите фоновый процесс') ]}
                        >
                            <Select
                                virtual={false}
                                showSearch={isBeingEdited}
                                placeholder='...'
                                optionFilterProp='children'
                                filterOption={filterOption}
                                options={backgroundJobIdOptions}
                                open={isBackgroundJobIdSelectOpen}
                                onDropdownVisibleChange={onBackgroundJobIdSelectOpenChange}
                            />
                        </Form.Item>
                    )}

                    {!isCreationForm && (
                        <>
                            <Descriptions layout='vertical' size='small'>
                                <Descriptions.Item label='Следующий запуск'>
                                    {formatDateTime(backgroundJob.next_run_time, true)}
                                </Descriptions.Item>
                            </Descriptions>

                            <Divider />
                        </>
                    )}

                    <Form.Item
                        name='day_of_week'
                        label='День недели'
                        rules={[ makeRequiredRule('Выберите день недели') ]}
                    >
                        <Select
                            virtual={false}
                            showSearch={isBeingEdited}
                            placeholder='...'
                            optionFilterProp='children'
                            filterOption={filterOption}
                            options={weekDayOptions}
                            open={isWeekDaySelectOpen}
                            onDropdownVisibleChange={onWeekDaySelectOpenChange}
                        />
                    </Form.Item>
                    <Form.Item
                        name='time'
                        label='Время'
                        rules={[ makeRequiredRule('Выберите время') ]}
                        style={{ marginBottom: 0 }}
                    >
                        <TimePicker
                            changeOnBlur
                            format='HH:mm'
                            locale={ruLocale}
                            allowClear={false}
                            inputReadOnly={!isBeingEdited}
                            placeholder='...'
                            open={isBeingEdited && isTimePickerOpen}
                            onOpenChange={onTimePickerOpenChange}
                            className='width--full-size'
                        />
                    </Form.Item>
                </Form>
            </CardWithButtonGroup>
        </>
    );
};


export default BackgroundJob;
