import { useCallback, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthContext } from './hooks/contexts';

import ApiMiddleware from './components/ApiMiddleware';
import AppRouter from './components/AppRouter';
import NavigationMenu from './components/NavigationMenu';


const App = () => {
    const [ isAuthorized, setIsAuthorized ] = useState(
        localStorage.getItem('accessToken') !== null &&
        localStorage.getItem('refreshToken') !== null &&
        localStorage.getItem('userRole') !== null
    );

    const signIn = useCallback((accessToken, refreshToken, userRole) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userRole', userRole);

        setIsAuthorized(true);
    }, []);
    const signOut = useCallback(() => {
        localStorage.clear();

        setIsAuthorized(false);
    }, []);
    const getAuthorizationData = useCallback(() => ({
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        userRole: localStorage.getItem('userRole'),
    }), []);

    const authContextValue = useMemo(() => ({
        isAuthorized, signIn, signOut, getAuthorizationData,
    }), [ isAuthorized, signIn, signOut, getAuthorizationData ]);

    return (
        <AuthContext.Provider value={authContextValue}>
            <ApiMiddleware>
                <BrowserRouter>
                    {isAuthorized && <NavigationMenu />}

                    <AppRouter />
                </BrowserRouter>
            </ApiMiddleware>
        </AuthContext.Provider>
    );
};


export default App;
